













import { CommentModel } from '@/models/post-model'
import { PostStore } from '@/stores/post-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class CommentTranslateButton extends Vue {
  @Prop() comment!: CommentModel
  @Prop() postStore!: PostStore
}
